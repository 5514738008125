figure.heartbeat {
    $size: 60vmin;

    &.heart {
        $color-active: #f54639;
        $color-inactive: desaturate(lighten($color-active, 30%), 20%);

        position: absolute;
        left: 50%; top: 50%;

        transform: translate(-50%, -50%);

        > .beater {
            width: 60vmin;
            height: auto;

            fill: $color-inactive;
            transform: scale(0.8);
            transition: fill 1s, transform 1s;

            &[data-beat] { animation: heartbeat-heart; }

            @keyframes heartbeat-heart {
                0% {
                    fill: $color-inactive;
                    transform: scale(0.8);
                }
                15% {
                    fill: mix($color-active, $color-inactive, 50%);
                    transform: scale(0.9);
                }
                25% {
                    fill: mix($color-active, $color-inactive, 50%);
                    transform: scale(0.85);
                }
                40% {
                    fill: $color-active;
                    transform: scale(1);
                }
                42% {
                    fill: $color-active;
                    transform: scale(1);
                }
                100% {
                    fill: $color-inactive;
                    transform: scale(0.8);
                }
            }
        }

        > h1 {
            position: absolute;
            left: 50%; top: 50%;

            font-family: monospace;
            font-size: $size / 6;
            color: #fff;

            transform: translate(-50%, -80%);
            transition: opacity 1s;

            pointer-events: none;
            user-select: none;
        }

        &:not([data-active]) {
            > h1 { opacity: 0; }
        }
        &[data-active] {
            > h1 { opacity: 1; }
        }
    }

    &.ekg {
        > svg {
            position: absolute;
            bottom: 0;
            left: 0; right: 0;
            width: 100%;
            height: auto;

            .beater {
                $stroke-size: 2;
                $stroke-length: 16;

                stroke-dasharray: $stroke-size $stroke-length;
                stroke-dashoffset: $stroke-size;

                &[data-beat] { animation: heartbeat-ekg cubic-bezier(0.465, 0.690, 0.395, 0.815); }

                @keyframes heartbeat-ekg {
                    0% {
                        stroke-dashoffset: $stroke-size;
                    }
                    100% {
                        stroke-dashoffset: -$stroke-length;
                    }
                }
            }
        }

        > h1 {
            position: absolute;
            right: 13.5rem; bottom: 2.25rem;

            font-family: "Silkscreen (Ruirize)";
            font-weight: 400;
            font-size: 9rem;
            color: #fff;
            text-shadow: 0.116em 0.116em 0 #000;

            transform: translate(-50%, -80%);
            transition: opacity 1s;

            pointer-events: none;
            user-select: none;
        }

        &:not([data-active]) {
            > h1 { opacity: 0; }
        }
        &[data-active] {
            > h1 { opacity: 1; }
        }
    }
}
