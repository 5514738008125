figure.chart {
    canvas {
        position: absolute;
        left: 0; right: 0;
        top: 0; bottom: 0;
        width: 100%;
        height: 100%;

        mask-image: linear-gradient(to right, rgba(#000, 0) 0%, rgba(#000, 1) 25%);
    }

    > section {
        position: absolute;
        top: 50%;
        left: 5%;

        transform: translateY(-50%);

        > p {
            display: inline-block;

            & + p { margin-left: 4rem; }

            color: #fff;
            font-family: "Bebas Kai";
            font-size: 48px;
            text-shadow: 2px 2px 0 #000, -2px 2px 0 #000, 2px -2px 0 #000, -2px -2px 0 #000;
            text-align: center;
            line-height: 0.8;

            transition: opacity 1s;

            &:after {
                display: block;

                font-size: 32px;
            }

            &:empty { opacity: 0; }

            &[data-purpose="heart-rate"]:after { content: "bpm"; }
            &[data-purpose="calories"]:after { content: "kcal"; }
        }
    }
}
