@import "mixins";
@import "functions";
@import "variables";

*, *:before, *:after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html {
    background: #feebee;
}

html, body {
    font-family: sans-serif;
    font-style: normal;
    font-weight: normal;
}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@import "components";
